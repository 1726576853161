import React from 'react'
import { AnexSimPurchase } from '@anex/sim-purchase'

import { Layout } from '../layouts/Layout'
import { SEO } from '../components/shared'

const windowGlobal = typeof window !== 'undefined' && window

const SimPurchase = () => (
  <Layout>
    <SEO
      title="Pronto Mobile"
      description="Simple mobile phone and data plans powered by the Optus 4G network."
    />
    <main id="page-sign-up" className="single">
      <div className="container">
        <AnexSimPurchase
          wholesaleCustomerCode="PB"
          anexApiBaseUrl={windowGlobal['ANEX_API_ENDPOINT']}
          anexApiToken={windowGlobal['ANEX_API_TOKEN']}
          bPointBaseUrl={windowGlobal['BPOINT_API_ENDPOINT']}
          eWayBaseUrl={windowGlobal['EWAY_API_ENDPOINT']}
          googleApiKey={windowGlobal['GOOGLE_API_KEY']}
          saleFailureAdvice={windowGlobal['SALE_FAILURE_ADVICE']}
          authEndPointUrl={windowGlobal['AUTH_ENDPOINT']}
        />
      </div>
    </main>
  </Layout>
)

export default SimPurchase
